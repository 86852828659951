<template>
  <section class="flex flex-col bg-gray-50 h-screen" v-if="openMenu">
  <!-- ***************** MENU SCREEN ***************** -->

<!-- Off-canvas menu for mobile, show/hide based on off-canvas menu state. -->
  <div class="relative z-50" role="dialog" aria-modal="true">
    <!--
      Off-canvas menu backdrop, show/hide based on off-canvas menu state.

      Entering: "transition-opacity ease-linear duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "transition-opacity ease-linear duration-300"
        From: "opacity-100"
        To: "opacity-0"
    -->
    <div class="fixed inset-0 bg-gray-900/80"></div>

    <div class="fixed inset-0 flex">
      <!--
        Off-canvas menu, show/hide based on off-canvas menu state.

        Entering: "transition ease-in-out duration-300 transform"
          From: "-translate-x-full"
          To: "translate-x-0"
        Leaving: "transition ease-in-out duration-300 transform"
          From: "translate-x-0"
          To: "-translate-x-full"
      -->
      <div class="relative mr-16 flex w-full max-w-xs flex-1">
        <!--
          Close button, show/hide based on off-canvas menu state.

          Entering: "ease-in-out duration-300"
            From: "opacity-0"
            To: "opacity-100"
          Leaving: "ease-in-out duration-300"
            From: "opacity-100"
            To: "opacity-0"
        -->
        <div class="absolute left-full top-0 flex w-16 justify-center pt-5">
          <button type="button" class="-m-2.5 p-2.5" v-on:click="closeMenu()">
            <span class="sr-only">Close sidebar</span>
            <svg class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        <!-- Sidebar component, swap this element with another sidebar if you like -->
        <div class="flex grow flex-col gap-y-0 overflow-y-auto bg-gray-50 px-6 pb-4">
          <div class="flex h-16 shrink-0 items-center">
            <div class="h-6 shrink-0">
              <img style="height: 26px" src="../assets/logo.png" />
            </div>
          </div>
          <nav class="flex flex-1 flex-col">
            <ul role="list" class="flex flex-1 flex-col gap-y-7">
              <li>
                <ul role="list" class="-mx-2 space-y-1">
                  <li>
                    <!-- Current: "bg-gray-200 text-primary", Default: "text-secondary hover:bg-gray-200" -->
                    <a href="#/bookings" class="bg-gray-200 text-primary group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold">
                      <div class="h-6 w-6 shrink-0">
                        <svg class="h-6 w-6 shrink-0 fill-primary" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_10_4440)">
                        <path d="M18.5 12C18.5 13.7239 17.8152 15.3772 16.5962 16.5962C15.3772 17.8152 13.7239 18.5 12 18.5C10.2761 18.5 8.62279 17.8152 7.40381 16.5962C6.18482 15.3772 5.5 13.7239 5.5 12C5.5 10.2761 6.18482 8.62279 7.40381 7.40381C8.62279 6.18482 10.2761 5.5 12 5.5C13.7239 5.5 15.3772 6.18482 16.5962 7.40381C17.8152 8.62279 18.5 10.2761 18.5 12ZM4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12ZM11.25 7.75V12C11.25 12.25 11.375 12.4844 11.5844 12.625L14.5844 14.625C14.9281 14.8562 15.3938 14.7625 15.625 14.4156C15.8562 14.0687 15.7625 13.6062 15.4156 13.375L12.75 11.6V7.75C12.75 7.33437 12.4156 7 12 7C11.5844 7 11.25 7.33437 11.25 7.75Z"/>
                        </g>
                        </svg>
                      </div>
                      Tur liste
                    </a>
                  </li>
                  <li>
                    <a href="#" class="text-secondary hover:bg-gray-200 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-medium">
                      <div class="h-6 w-6 shrink-0">
                      </div>
                      Tidsregistrering
                    </a>
                  </li>
                  <li>
                    <a href="#" class="text-secondary hover:bg-gray-200 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-medium">
                      <div class="h-6 w-6 shrink-0">
                      </div>
                      Dokumenter
                    </a>
                  </li>
                </ul>
              </li>
              <li class="mt-auto">
                <a href="#/login" class="text-secondary hover:bg-gray-200 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold" v-on:click="signOut()">
                  <div class="h-6 w-6 shrink-0">
                    <svg class="h-6 w-6 shrink-0 fill-primary" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M96 64h84c6.6 0 12 5.4 12 12v24c0 6.6-5.4 12-12 12H96c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h84c6.6 0 12 5.4 12 12v24c0 6.6-5.4 12-12 12H96c-53 0-96-43-96-96V160c0-53 43-96 96-96zm231.1 19.5l-19.6 19.6c-4.8 4.8-4.7 12.5 .2 17.1L420.8 230H172c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h248.8L307.7 391.7c-4.8 4.7-4.9 12.4-.2 17.1l19.6 19.6c4.7 4.7 12.3 4.7 17 0l164.4-164c4.7-4.7 4.7-12.3 0-17l-164.4-164c-4.7-4.6-12.3-4.6-17 .1z"/></svg>
                  </div>
                  Log af
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>

  <header class="w-full h-16 shrink-0 bg-white shadow justify-start items-center inline-flex">
    <button class="flex w-16 self-stretch justify-center items-center">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4 6H20M4 12H20M4 18H20" stroke="#003463" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </button>

    <div class="grow shrink basis-0 self-stretch flex-col justify-center items-center gap-2.5 inline-flex">
      <svg width="52" height="26" viewBox="0 0 145 78" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M30.8186 41.5671C31.0153 40.481 31.6542 39.9873 32.6864 39.9873H39.961C41.0424 39.9873 41.4847 40.481 41.2881 41.5671L40.7475 44.5291C40.6 45.6152 39.961 46.1089 38.8797 46.1089H31.6051C30.5729 46.1089 30.1305 45.5658 30.278 44.5291L30.8186 41.5671Z" fill="#231F20"/>
        <path d="M47.678 42.0114C45.2695 41.3203 43.9915 38.8519 44.483 36.1367L45.1712 32.0886C45.8102 28.1886 49.2017 25.3253 53.1339 25.3253H65.3729C66.4051 25.3253 66.8475 25.819 66.7 26.9051L66.1593 29.8671C66.0119 30.9532 65.3729 31.4468 64.2915 31.4468H55.2966C53.9203 31.4468 53.183 31.743 52.9373 33.0266V33.1253C52.7407 34.2114 53.0847 34.9025 53.9203 35.1L62.0797 37.3215C64.439 37.9633 65.8153 40.1354 65.2746 43.1962L64.5373 47.1949C63.8983 51.243 60.6542 54.057 56.6729 54.057H43.6475C42.5661 54.057 42.1237 53.5633 42.3203 52.4772L42.8119 49.5152C43.0085 48.4291 43.6475 47.9354 44.6797 47.9354H54.5102C55.8864 47.9354 56.5746 47.6392 56.7712 46.3557L56.8203 46.257C57.0169 45.1215 56.6729 44.4797 55.8373 44.2329L47.678 42.0114Z" fill="#231F20"/>
        <path d="M76.7763 31.4468H69.9441C68.9119 31.4468 68.4695 30.9532 68.617 29.8671L69.1085 26.9051C69.2559 25.819 69.8949 25.3253 70.9763 25.3253H92.5051C93.5373 25.3253 93.9797 25.819 93.8322 26.9051L93.2915 29.8671C93.1441 30.9532 92.5051 31.4468 91.4237 31.4468H84.5915L80.9542 52.4772C80.8068 53.5633 80.217 54.057 79.1356 54.057H74.417C73.3356 54.057 72.8932 53.5633 73.0898 52.4772L76.7763 31.4468Z" fill="#231F20"/>
        <path d="M136.742 25.3253C140.773 25.3253 143.083 28.1392 142.395 32.138L141.756 35.7418C141.461 37.9633 139.151 40.2835 136.693 41.3696C137.283 41.962 137.775 43.0975 138.02 44.0848L139.692 52.2797C139.839 53.2177 139.003 54.057 138.02 54.057H132.515C131.778 54.057 131.385 53.6127 131.286 52.9709L129.763 43.9367C129.615 43.2949 128.78 42.6038 127.993 42.6038H126.469L124.7 52.4772C124.553 53.5633 123.963 54.057 122.881 54.057H118.163C117.081 54.057 116.639 53.5633 116.836 52.4772L121.259 26.9051C121.407 25.819 122.046 25.3253 123.127 25.3253H136.742ZM129.173 31.4468C128.731 31.4468 128.386 31.7924 128.239 32.1873L127.502 36.4823H130.992C132.712 36.4823 133.892 35.5443 134.186 34.0139L134.236 33.8658C134.531 32.0886 133.646 31.4962 131.925 31.4962H129.173V31.4468Z" fill="#231F20"/>
        <path d="M29.8356 52.4772L23.1017 43.7886C22.3644 42.8013 21.3814 42.3076 20.1525 42.357L16.8102 42.4557C15.6305 42.5051 15.3847 42.9987 16.1712 43.9861L18.4322 46.8987C17.8424 47.5405 17.0068 47.9354 16.0237 47.9354H13.861C12.1407 47.9354 11.3051 46.8494 11.6 45.1215L13.517 34.2114C13.8119 32.4342 15.0407 31.3975 16.7119 31.3975H18.8746C20.5949 31.3975 21.4305 32.3848 21.1356 34.2114L19.9068 41.1721H20.8898C22.1186 41.1721 23.1017 41.6658 23.839 42.6038L26.8864 46.5038L29.3932 32.0392C30.0322 28.1392 27.8203 25.2759 23.7898 25.2759H13.9102C9.92881 25.2759 6.58644 28.1392 5.94746 32.0392L3.34237 47.1949C2.70339 51.1937 4.81695 54.057 8.94576 54.057H18.9237C20.4966 54.057 21.922 53.6126 23.1508 52.8721C23.8881 53.7114 24.822 54.1063 25.9034 54.057L29.2458 53.9582C30.3763 54.0076 30.5729 53.4645 29.8356 52.4772Z" fill="#231F20"/>
        <path d="M77.5627 60.4253C77.8576 60.4253 78.1034 60.6721 78.1034 60.9683V62.2025C78.1034 62.4987 77.9068 62.7455 77.5627 62.7455H71.9102C71.7136 62.7455 71.6153 62.8443 71.6153 63.0417V66.2012H76.4814C76.8746 66.2012 77.1203 66.3987 77.1203 66.843V67.9291C77.1203 68.324 76.9237 68.5215 76.4814 68.5215H71.6153V71.8785C71.6153 72.0759 71.7136 72.1747 71.9102 72.1747H77.5627C77.8576 72.1747 78.1034 72.4215 78.1034 72.7177V73.9519C78.1034 74.2481 77.8576 74.4949 77.5627 74.4949H69.2559C68.961 74.4949 68.7153 74.2481 68.7153 73.9519V61.0671C68.7153 60.7709 68.961 60.524 69.2559 60.524H77.5627V60.4253Z" fill="#231F20"/>
        <path d="M82.478 63.8316V73.8532C82.478 74.2481 82.2814 74.4456 81.8881 74.4456H80.3153C79.922 74.4456 79.7254 74.2481 79.7254 73.8532V61.0671C79.7254 60.6721 79.922 60.4747 80.3153 60.4747H83.0678C83.4119 60.4747 83.6085 60.6721 83.7068 60.919L88.0322 71.0886V61.0671C88.0322 60.6721 88.2288 60.4747 88.622 60.4747H90.1949C90.5881 60.4747 90.7847 60.6721 90.7847 61.0671V73.8532C90.7847 74.2481 90.5881 74.4456 90.1949 74.4456H87.3932C87.0983 74.4456 86.8525 74.2481 86.7542 74.0012L82.478 63.8316Z" fill="#231F20"/>
        <path d="M101.942 60.4253C102.237 60.4253 102.483 60.6721 102.483 60.9683V62.2025C102.483 62.4987 102.286 62.7455 101.942 62.7455H96.2898C96.0932 62.7455 95.9949 62.8443 95.9949 63.0417V66.2012H100.861C101.254 66.2012 101.5 66.3987 101.5 66.843V67.9291C101.5 68.324 101.303 68.5215 100.861 68.5215H95.9949V71.8785C95.9949 72.0759 96.0932 72.1747 96.2898 72.1747H101.942C102.237 72.1747 102.483 72.4215 102.483 72.7177V73.9519C102.483 74.2481 102.237 74.4949 101.942 74.4949H93.6356C93.3407 74.4949 93.0949 74.2481 93.0949 73.9519V61.0671C93.0949 60.7709 93.3407 60.524 93.6356 60.524H101.942V60.4253Z" fill="#231F20"/>
        <path d="M110.839 60.4253C113.002 60.4253 114.28 61.7582 114.28 63.8316V65.0658C114.28 66.6455 113.149 67.6329 111.871 68.0278C112.166 68.2747 112.412 68.6696 112.559 69.0645L114.624 73.705C114.771 74.0506 114.476 74.3962 114.083 74.3962H112.068C111.822 74.3962 111.625 74.2481 111.527 74.0012L109.512 69.1139C109.364 68.8177 108.922 68.4721 108.529 68.4721H107.054V73.8038C107.054 74.1987 106.858 74.3962 106.464 74.3962H104.744C104.351 74.3962 104.154 74.1987 104.154 73.8038V61.0177C104.154 60.6228 104.351 60.4253 104.744 60.4253H110.839ZM107.398 62.7455C107.202 62.7455 107.054 62.8936 107.054 63.0417V66.2012H109.512C110.741 66.2012 111.331 65.6582 111.331 64.4734V64.424C111.331 63.1898 110.741 62.7455 109.512 62.7455H107.398Z" fill="#231F20"/>
        <path d="M116.246 63.8316C116.246 61.7582 117.524 60.4253 119.686 60.4253H125.241C125.536 60.4253 125.781 60.6721 125.781 60.9683V62.2025C125.781 62.4987 125.536 62.7455 125.241 62.7455H121.014C119.785 62.7455 119.146 63.338 119.146 64.5721V70.2987C119.146 71.4835 119.785 72.1253 120.964 72.1253H123.127C123.324 72.1253 123.422 72.0266 123.422 71.8291V67.2873C123.422 66.9911 123.668 66.7443 123.963 66.7443H125.781C126.076 66.7443 126.322 66.9911 126.322 67.2873V73.9025C126.322 74.1987 126.076 74.4455 125.781 74.4455H119.637C117.524 74.4455 116.246 73.1126 116.246 71.0392V63.8316Z" fill="#231F20"/>
        <path d="M130.402 60.4253C130.598 60.4253 130.746 60.524 130.844 60.6721L133.646 65.6582L136.447 60.6721C136.546 60.524 136.693 60.4253 136.89 60.4253H139.102C139.397 60.4253 139.79 60.8202 139.544 61.2645L135.071 68.719V73.8038C135.071 74.1987 134.875 74.3962 134.481 74.3962H132.761C132.368 74.3962 132.171 74.1987 132.171 73.8038V68.719L127.698 61.2645C127.551 60.9683 127.649 60.4253 128.141 60.4253H130.402Z" fill="#231F20"/>
        <path d="M14.9915 58.7468C14.9915 58.0063 15.0407 57.2165 15.0898 56.476H8.99491C8.25763 63.6342 11.9932 86.7873 66.7 65.9051V65.0165C22.7576 81.357 14.9915 64.1279 14.9915 58.7468Z" fill="#0079C0"/>
        <path d="M111.969 37.1734C121.407 27.6456 124.602 15.5506 118.359 9.87341C104.547 -2.7152 58.0492 1.6291 28.8034 24.4861C29.2949 24.8316 29.6881 25.2266 30.0814 25.6709C30.5237 26.2139 30.8678 26.757 31.1627 27.3987C31.3593 27.2506 31.5068 27.0532 31.6542 26.9544C37.8966 21.4253 46.2034 15.6 55.6407 11.6506C82.6746 0.394928 106.907 1.77721 116.098 14.0696C119.686 18.8582 118.163 29.5215 111.92 36.5316L110.986 26.5595C110.888 25.8683 110.593 25.3747 109.61 25.3747H100.959C100.025 25.3747 99.6322 25.8683 99.3373 26.5595L87.9339 52.4772C87.5407 53.4152 88.0322 54.1063 89.0153 54.1557C85.8695 55.8835 84.1983 57.1177 81.6915 58.5494H83.0186C85.083 57.3645 87.2949 55.9329 90.1458 54.2051H111.871C112.953 54.2051 113.592 53.4646 113.444 52.4772L111.969 37.1734Z" fill="#0079C0"/>
        <path d="M94.7661 36.8278C94.7661 36.8278 98.2068 39.0494 102.925 39.0987C108.48 39.0494 111.92 36.4823 111.92 36.4823L111.969 37.1734C108.381 38.4076 106.12 41.9126 105.629 44.6278C104.941 48.4291 105.481 50.4532 109.659 54.1063H108.48C108.48 54.1063 104.449 48.6759 101.451 48.6759C98.9441 48.6759 93.6356 50.5519 90.0475 54.1063C89.5559 54.1063 88.9169 54.1063 88.9169 54.1063C88.9169 54.1063 99.4847 46.6025 99.8288 43.5418C100.32 39.543 94.5203 37.3215 94.5203 37.3215L94.7661 36.8278Z" fill="white"/>
        <path d="M101.254 34.9519C101.5 33.4709 102.925 32.2367 104.449 32.2367C105.924 32.2367 106.907 33.4709 106.661 34.9519C106.415 36.4329 104.99 37.6671 103.466 37.6671C101.992 37.6671 101.008 36.4823 101.254 34.9519Z" fill="white"/>
      </svg>
    </div>

    <button class="flex w-16 self-stretch px-4 justify-start items-center">
        <div class="w-8 h-8 relative bg-gray-100 rounded-2xl">
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_227_340)">
            <rect width="32" height="32" rx="16" fill="#F3F4F6"/>
            <path d="M32 27.992V32.0013H0V28.0067C1.86127 25.5193 4.27721 23.5004 7.05572 22.1107C9.83423 20.721 12.8987 19.9987 16.0053 20.0013C22.544 20.0013 28.352 23.14 32 27.992ZM21.336 12C21.336 13.4145 20.7741 14.771 19.7739 15.7712C18.7737 16.7714 17.4172 17.3333 16.0027 17.3333C14.5882 17.3333 13.2316 16.7714 12.2314 15.7712C11.2312 14.771 10.6693 13.4145 10.6693 12C10.6693 10.5855 11.2312 9.22895 12.2314 8.22875C13.2316 7.22856 14.5882 6.66666 16.0027 6.66666C17.4172 6.66666 18.7737 7.22856 19.7739 8.22875C20.7741 9.22895 21.336 10.5855 21.336 12Z" fill="#D1D5DB"/>
            </g>
            <defs>
            <clipPath id="clip0_227_340">
            <rect width="32" height="32" rx="16" fill="white"/>
            </clipPath>
            </defs>
          </svg>
        </div>
    </button>
  </header>

  <section class="flex flex-col space-y-4">

  </section>

</section>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import applicationUserManager from '@/core/applicationusermanager'

// const emit = defineEmits(['update:openMenu'])

export default defineComponent({
  name: 'menuComponent',
  props: {
    openMenu: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    function closeMenu () {
      emit('update:openMenu', false)
    }

    function signOut () {
      applicationUserManager.logout()
      emit('update:openMenu', false)
    }

    return {
      closeMenu,
      signOut,
      props
    }
  }
})
</script>
