<template>
  <div class="relative z-50 w-full h-full" aria-labelledby="slide-over-title" role="dialog" aria-modal="true">
    <transition name="fade" enter-active-class="ease-in-out duration-500" leave-active-class="ease-in-out duration-500"
      enter-from-class="opacity-0" enter-to-class="opacity-100" leave-from-class="opacity-100"
      leave-to-class="opacity-0">
      <div v-if="isVisible" class="fixed inset-0 bg-gray-700 bg-opacity-80 transition-opacity"></div>
    </transition>
    <transition name="slide" enter-active-class="transform transition ease-in-out duration-500 sm:duration-700"
      enter-from-class="translate-x-full" enter-to-class="translate-x-0"
      leave-active-class="transform transition ease-in-out duration-500 sm:duration-700"
      leave-from-class="translate-x-0" leave-to-class="translate-x-full">
      <div v-if="isVisible" class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <div class="pointer-events-auto relative w-screen max-w-md">
              <div class="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
                <button @click="close()" type="button"
                  class="relative rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white">
                  <span class="absolute -inset-2.5"></span>
                  <span class="sr-only">Close panel</span>
                  <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                    aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>

              <div class="flex h-full flex-col overflow-y-scroll bg-gray-100 py-6 shadow-xl">
                <div class="flex flex-col gap-4 px-6 sm:px-6">

                  <div class="flex grow flex-col">

                    <div class="text-base font-medium leading-5 text-gray-800">

                      <div class="flex justify-between items-center">

                        <div class="text-sm text-gray-400">
                          STOP
                        </div>
                        <!-- Change address-->
                        <!--
                        <div v-if="!hasInvalidAddress && !details.stopDone && details.stopStarted" @click="editStop()">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                            class="size-6">
                            <path fill-rule="evenodd"
                              d="M15.97 2.47a.75.75 0 0 1 1.06 0l4.5 4.5a.75.75 0 0 1 0 1.06l-4.5 4.5a.75.75 0 1 1-1.06-1.06l3.22-3.22H7.5a.75.75 0 0 1 0-1.5h11.69l-3.22-3.22a.75.75 0 0 1 0-1.06Zm-7.94 9a.75.75 0 0 1 0 1.06l-3.22 3.22H16.5a.75.75 0 0 1 0 1.5H4.81l3.22 3.22a.75.75 0 1 1-1.06 1.06l-4.5-4.5a.75.75 0 0 1 0-1.06l4.5-4.5a.75.75 0 0 1 1.06 0Z"
                              clip-rule="evenodd" />
                          </svg>
                        </div>
                        -->
                      </div>
                      {{ details.fromAddressStop }}
                    </div>
                  </div>

                  <div>
                    <label for="transport_type" class="block text-sm font-medium leading-6 text-secondary">Navn</label>
                    <div v-if="hasInvalidAddress" class="relative">
                      <SmartSelect :items="systemLocationsTransformed" @update:value="handleAddressValueUpdate" />
                    </div>
                    <div v-else>
                      {{ details.fromAddressStop }}
                    </div>
                  </div>


                  <div v-if="selectedAddressValue && hasInvalidAddress">
                    <button type="submit" @click="saveOptionStop(selectedAddressValue)"
                      class="flex w-full justify-center rounded-md bg-blue-400 px-3 py-2 font-medium leading-6 text-white shadow-sm hover:bg-gray-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary">
                      Gem og fastlås adresse
                    </button>
                  </div>

                  <div>
                    <div class="flex grow flex-col">
                      <div class="text-sm text-gray-400">Adresse</div>
                      <div class="text-base font-medium leading-5 text-gray-800">
                        {{ details.fromAddressStreet }}
                      </div>
                    </div>
                  </div>

                  <div>
                    <div class="flex grow flex-col">
                      <div class="text-sm text-gray-400">By</div>
                      <div class="text-base font-medium leading-5 text-gray-800">
                        {{ details.fromAddressCity }}
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="!hasInvalidAddress" class="flex flex-col relative h-fit mt-6 bg-white px-4 sm:px-6">
                  <div v-if="details.stopDone" class="p-2">
                    <h2 v-if="details.reL_StopType === 1" class="text-xl font-medium mt-1 text-primary">Afhentning</h2>
                    <h2 v-else-if="details.reL_StopType === 2" class="text-xl font-medium mt-1 text-primary">Aflevering</h2>
                  </div>
                  <div v-else class="relative my-4"
                    :class="{ 'opacity-20 pointer-events-none': !(details.stopStarted === true && (details.reL_StopType === 1 || details.reL_StopType === 2)) && (details.canStart === false || (details.stopStarted && !details.stopEnded)) }">

                    <select id="transport_type" :disabled="!isDisabled && details.stopDone"
                      :class="{ 'opacity-20 pointer-events-none': activeDeviation && booking.currentStop.secondaryAddressId }"
                      class="block w-full appearance-none text-sm font-medium rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-200 focus:ring-2 focus:ring-inset focus:ring-secondary sm:text-sm sm:leading-6"
                      aria-labelledby="transport_type" v-model="selectedOptionStopType">
                      <option value="" disabled selected>Vælg stop type</option>
                      <option value="1">Afhentning</option>
                      <option value="2">Aflevering</option>
                      <option value="3" v-if="details.reL_StopType !== 2">Intet gods</option>
                      <!-- Kun muligt ved predefineret stop type delivery/aflevering -->
                      <option v-if="details.reL_StopType == 2" value="4">Fjern stop</option>
                    </select>
                    <div
                      :class="{ 'opacity-20 pointer-events-none': activeDeviation && booking.currentStop.secondaryAddressId }"
                      class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-400">
                      <svg class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M5.29289 7.29289C5.68342 6.90237 6.31658 6.90237 6.7071 7.29289L9.99999 10.5858L13.2929 7.29289C13.6834 6.90237 14.3166 6.90237 14.7071 7.29289C15.0976 7.68342 15.0976 8.31658 14.7071 8.70711L10.7071 12.7071C10.3166 13.0976 9.68341 13.0976 9.29289 12.7071L5.29289 8.70711C4.90237 8.31658 4.90237 7.68342 5.29289 7.29289Z"
                          fill="#6B7280" />
                      </svg>
                    </div>
                  </div>
                  <div v-if="details.stopDone" class="divide-y divide-gray-100 border-y border-gray-100 p-0 mb-2"></div>
                  <div>
                    <div v-if="selectedOptionStopType === '1'" class="px-2">
                      <div
                        :class="{ 'opacity-20 pointer-events-none': details.stopStarted === false && details.stopDone === false }">
                        <div class="flex items-center mb-4">
                          <div class="grow">
                            <h1 class="text-xl font-medium mt-2 text-primary">Gods</h1>
                          </div>
                          <div v-if="!details.stopDone" @click="toggleEditMode">
                            <div v-if="isEditMode && !details.stopDone">
                              <div class="mr-1 text-xs text-gray-400">
                                Gem angivelse af gods
                              </div>
                            </div>
                            <div v-else-if="!isEditMode && !details.stopDone">
                              <div class="mr-1 text-xs text-gray-400">
                                Rediger angivelse af gods
                              </div>
                            </div>
                          </div>
                          <div v-if="!details.stopDone" @click="toggleEditMode">
                            <svg v-if="!isEditMode && !details.stopDone" width="38" height="38" viewBox="0 0 38 38"
                              fill="none" xmlns="http://www.w3.org/2000/svg">
                              <g filter="url(#filter0_d_479_1197)">
                                <rect x="2.5" y="1.5" width="33" height="33" rx="16.5" stroke="#858E97" />
                                <path
                                  d="M23.6017 14.438L22.5498 13.3864C22.0346 12.8712 21.2006 12.8712 20.6853 13.3864L14.4865 19.5447L14.0064 22.4548C13.9542 22.7715 14.2285 23.0458 14.5452 22.9936L17.4553 22.5135L23.614 16.3147C24.1283 15.8004 24.1332 14.9694 23.6017 14.438ZM16.3563 18.8984L19.6817 15.5946L21.4053 17.3183L18.1016 20.6436V19.8359H17.1641V18.8984H16.3563ZM15.6041 21.9479L15.0521 21.3959L15.2892 19.9586L15.6486 19.6016H16.4609V20.5391H17.3984V21.3514L17.0414 21.7108L15.6041 21.9479ZM23.0062 15.707L23.0052 15.708L23.0042 15.709L22.0661 16.6532L20.3468 14.9339L21.291 13.9958L21.292 13.9948L21.2929 13.9938C21.4716 13.8152 21.7613 13.813 21.9421 13.9938L22.9939 15.0457C23.1868 15.2386 23.188 15.5251 23.0062 15.707Z"
                                  fill="#858E97" />
                              </g>
                            </svg>
                            <svg v-if="isEditMode && !details.stopDone" width="38" height="38" viewBox="0 0 38 38"
                              fill="none" xmlns="http://www.w3.org/2000/svg">
                              <g filter="url(#filter0_d_555_889)">
                                <rect x="2" y="1" width="33" height="33" rx="17" fill="#374151" />
                                <path
                                  d="M24.6223 14.6223L22.375 12.375C22.1339 12.1347 21.8073 11.9999 21.467 12H14.2857C13.5759 12 13 12.5759 13 13.2857V22.7143C13 23.4241 13.5759 24 14.2857 24H23.7143C24.4241 24 25 23.4241 25 22.7143V15.533C24.9994 15.1923 24.8636 14.8657 24.6223 14.625V14.6223ZM19 22.2857C18.0545 22.2857 17.2857 21.517 17.2857 20.5714C17.2857 19.6259 18.0545 18.8571 19 18.8571C19.9455 18.8571 20.7143 19.6259 20.7143 20.5714C20.7143 21.517 19.9455 22.2857 19 22.2857ZM21.5714 14.1295V16.8214C21.5714 16.9982 21.4268 17.1429 21.25 17.1429H15.0357C14.8589 17.1429 14.7143 16.9982 14.7143 16.8214V14.0357C14.7143 13.8589 14.8589 13.7143 15.0357 13.7143H21.1562C21.242 13.7143 21.3223 13.7491 21.3839 13.808L21.4777 13.9018C21.5075 13.9317 21.5311 13.9672 21.5472 14.0063C21.5633 14.0454 21.5715 14.0872 21.5714 14.1295Z"
                                  fill="white" />
                              </g>
                            </svg>
                          </div>
                        </div>
                        <ul role="list" class="divide-y divide-gray-200 border-y border-gray-200 p-0">
                          <li class="m-0 h-16 flex items-center p-0">
                            <a class="flex w-full gap-x-3 no-underline py-4 items-center">
                              <div class="flex items-center shrink-0 space-x-2">
                                <div v-if="isEditMode && !details.stopDone">
                                  <input v-model="quantity" type="number" inputmode="numeric" class="
                                    bg-gray-100 border-b border-gray-300 text-gray-900 h-8 text-center
                                    block w-10 p-2.5 text-base outline-none font-medium"
                                    :class="{ 'ring-2 ring-red-500 shadow-lg shadow-red-500/50': mustEnterQuantityWarning }">
                                </div>
                                <div v-else>
                                  {{ quantity }}
                                </div>
                              </div>
                              <div class="text-gray-500">
                                x
                              </div>
                              <div class="grow relative">
                                <!-- <div class="text-base font-medium leading-5 text-gray-800">Paller ({{ details.quantity }})</div> -->
                                <div class="relative" v-if="isEditMode && !details.stopDone">
                                  <select id="transport_type"
                                    class="block w-full appearance-none outline-none text-base font-medium border-b border-gray-300 h-8 bg-gray-100 pl-3 pr-10 text-gray-900 sm:text-sm sm:leading-6"
                                    aria-labelledby="transport_type" v-model="selectedStopUnitType"
                                    :class="{ 'ring-2 ring-red-500 shadow-lg shadow-red-500/50': mustEnterStopUnitWarning }"
                                    @change="resetStopUnitWarning">
                                    <option value="" disabled selected>
                                      Vælg godstype
                                    </option>
                                    <option v-for="stopUnit of stopUnits" :key="stopUnit" :value="stopUnit">
                                      {{ stopUnit.name }}
                                    </option>
                                  </select>
                                  <div
                                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-400">
                                    <svg class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="none"
                                      xmlns="http://www.w3.org/2000/svg">
                                      <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M5.29289 7.29289C5.68342 6.90237 6.31658 6.90237 6.7071 7.29289L9.99999 10.5858L13.2929 7.29289C13.6834 6.90237 14.3166 6.90237 14.7071 7.29289C15.0976 7.68342 15.0976 8.31658 14.7071 8.70711L10.7071 12.7071C10.3166 13.0976 9.68341 13.0976 9.29289 12.7071L5.29289 8.70711C4.90237 8.31658 4.90237 7.68342 5.29289 7.29289Z"
                                        fill="#6B7280" />
                                    </svg>
                                  </div>
                                </div>
                                <div class="px-3 text-base font-medium h-8 content-center" v-else>
                                  {{ selectedStopUnitType?.name }}
                                </div>
                              </div>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div v-if="!details.stopDone" class="py-4">
                        <div
                          v-if="isEditMode && (!activeDeviation && !booking.currentStop.secondaryAddressId) && details.stopStarted"
                          class="bg-orange-500 opacity-80 mb-1 p-1 text-center font-medium text-white text-sm">
                          Gem angivelse af gods for at fortsætte
                        </div>
                        <button v-if="!activeDeviation && !booking.currentStop.secondaryAddressId && !details.stopDone"
                          type="submit"
                          class="flex w-full justify-center rounded-md bg-blue-400 px-3 py-2 font-medium leading-6 text-white shadow-sm hover:bg-gray-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary disabled:opacity-20"
                          :class="{ 'opacity-20 pointer-events-none': (isEditMode && details.stopStarted) || details.canStart === false || (quantity === '' && details.stopStarted) || cannotStartTemperatureCheck === true }"
                          @click="handleStopToggleAction()" :disabled="saving">
                          {{ details.stopStarted === false && details.stopDone === false ? 'Påbegynd afhentning' : 'Marker som afhentet' }}
                        </button>
                        <button type="submit"
                          v-if="(activeDeviation || booking.currentStop.secondaryAddressId) && details.canStart"
                          class="flex w-full justify-center rounded-md bg-red-400 px-3 py-2 font-medium leading-6 text-white shadow-sm hover:bg-gray-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary"
                          @click="endDeviation()">
                          Afslut først den aktive afvigelse
                        </button>
                        <label class="text-sm text-center" v-if="!details.canStart">
                          Start eller afslut venligst det forrige stop i rækkefølgen, før du fortsætter med dette.
                        </label>
                        <label class="text-sm text-center" v-if="cannotStartTemperatureCheck">
                          Nuværende temperatur på {{ stopRoute.currentTemperature }}°
                           er udenfor grænseværdierne min. {{ stopRoute.minTemperature }}°
                            og max. {{ stopRoute.maxTemperature }}°.
                            Afvent acceptabel temperatur eller kontakt din kørselsleder.
                        </label>
                      </div>
                    </div>
                    <div v-if="selectedOptionStopType === '2'" class="px-2">
                      <div
                        :class="{ 'opacity-20 pointer-events-none': details.stopStarted === false && details.stopDone === false }">
                        <div class="flex items-center mb-4">
                          <div class="grow">
                            <h1 class="text-xl font-medium text-primary">Gods</h1>
                          </div>
                          <div v-if="!details.stopDone" @click="toggleEditMode">
                            <div v-if="isEditMode && !details.stopDone">
                              <div class="mr-1 text-xs text-gray-400">
                                Gem angivelse af gods
                              </div>
                            </div>
                            <div v-else>
                              <div class="mr-1 text-xs text-gray-400">
                                Rediger angivelse af gods
                              </div>
                            </div>
                          </div>
                          <div v-if="!details.stopDone" @click="toggleEditMode">
                            <svg v-if="!isEditMode && !details.stopDone" width="38" height="38" viewBox="0 0 38 38"
                              fill="none" xmlns="http://www.w3.org/2000/svg">
                              <g filter="url(#filter0_d_479_1197)">
                                <rect x="2.5" y="1.5" width="33" height="33" rx="16.5" stroke="#858E97" />
                                <path
                                  d="M23.6017 14.438L22.5498 13.3864C22.0346 12.8712 21.2006 12.8712 20.6853 13.3864L14.4865 19.5447L14.0064 22.4548C13.9542 22.7715 14.2285 23.0458 14.5452 22.9936L17.4553 22.5135L23.614 16.3147C24.1283 15.8004 24.1332 14.9694 23.6017 14.438ZM16.3563 18.8984L19.6817 15.5946L21.4053 17.3183L18.1016 20.6436V19.8359H17.1641V18.8984H16.3563ZM15.6041 21.9479L15.0521 21.3959L15.2892 19.9586L15.6486 19.6016H16.4609V20.5391H17.3984V21.3514L17.0414 21.7108L15.6041 21.9479ZM23.0062 15.707L23.0052 15.708L23.0042 15.709L22.0661 16.6532L20.3468 14.9339L21.291 13.9958L21.292 13.9948L21.2929 13.9938C21.4716 13.8152 21.7613 13.813 21.9421 13.9938L22.9939 15.0457C23.1868 15.2386 23.188 15.5251 23.0062 15.707Z"
                                  fill="#858E97" />
                              </g>
                            </svg>
                            <svg v-if="isEditMode && !details.stopDone" width="38" height="38" viewBox="0 0 38 38"
                              fill="none" xmlns="http://www.w3.org/2000/svg">
                              <g filter="url(#filter0_d_555_889)">
                                <rect x="2" y="1" width="33" height="33" rx="17" fill="#374151" />
                                <path
                                  d="M24.6223 14.6223L22.375 12.375C22.1339 12.1347 21.8073 11.9999 21.467 12H14.2857C13.5759 12 13 12.5759 13 13.2857V22.7143C13 23.4241 13.5759 24 14.2857 24H23.7143C24.4241 24 25 23.4241 25 22.7143V15.533C24.9994 15.1923 24.8636 14.8657 24.6223 14.625V14.6223ZM19 22.2857C18.0545 22.2857 17.2857 21.517 17.2857 20.5714C17.2857 19.6259 18.0545 18.8571 19 18.8571C19.9455 18.8571 20.7143 19.6259 20.7143 20.5714C20.7143 21.517 19.9455 22.2857 19 22.2857ZM21.5714 14.1295V16.8214C21.5714 16.9982 21.4268 17.1429 21.25 17.1429H15.0357C14.8589 17.1429 14.7143 16.9982 14.7143 16.8214V14.0357C14.7143 13.8589 14.8589 13.7143 15.0357 13.7143H21.1562C21.242 13.7143 21.3223 13.7491 21.3839 13.808L21.4777 13.9018C21.5075 13.9317 21.5311 13.9672 21.5472 14.0063C21.5633 14.0454 21.5715 14.0872 21.5714 14.1295Z"
                                  fill="white" />
                              </g>
                            </svg>
                          </div>
                        </div>
                        <ul role="list" class="divide-y divide-gray-200 border-y border-gray-200 p-0">
                          <li class="m-0 h-16 flex items-center p-0">
                            <a class="flex w-full gap-x-3 no-underline py-4 items-center">
                              <div class="flex items-center shrink-0 space-x-2">
                                <div v-if="isEditMode && !details.stopDone">
                                  <input v-model="quantity" type="number" inputmode="numeric" class="
                                    bg-gray-100 border-b border-gray-300 text-gray-900 h-8 text-center
                                    block w-10 p-2.5 text-base outline-none font-medium"
                                    :class="{ 'ring-2 ring-red-500 shadow-lg shadow-red-500/50': mustEnterQuantityWarning }">
                                </div>
                                <div class="w-10 text-center text-base font-medium" v-else>
                                  {{ quantity }}
                                </div>
                              </div>
                              <div class="text-gray-500">
                                x
                              </div>
                              <div class="grow relative">
                                <!-- <div class="text-base font-medium leading-5 text-gray-800">Paller ({{ details.quantity }})</div> -->
                                <div class="relative" v-if="isEditMode && !details.stopDone">
                                  <select id="transport_type"
                                    class="block w-full appearance-none outline-none text-base font-medium border-b border-gray-300 h-8 bg-gray-100 pl-3 pr-10 text-gray-900 sm:text-sm sm:leading-6"
                                    aria-labelledby="transport_type" v-model="selectedStopUnitType"
                                    :class="{ 'ring-2 ring-red-500 shadow-lg shadow-red-500/50': mustEnterStopUnitWarning }"
                                    @change="resetStopUnitWarning">
                                    <option value="" disabled selected>
                                      Vælg godstype
                                    </option>
                                    <option v-for="stopUnit of stopUnits" :key="stopUnit" :value="stopUnit">
                                      {{ stopUnit.name }}
                                    </option>
                                  </select>
                                  <div
                                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-400">
                                    <svg class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="none"
                                      xmlns="http://www.w3.org/2000/svg">
                                      <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M5.29289 7.29289C5.68342 6.90237 6.31658 6.90237 6.7071 7.29289L9.99999 10.5858L13.2929 7.29289C13.6834 6.90237 14.3166 6.90237 14.7071 7.29289C15.0976 7.68342 15.0976 8.31658 14.7071 8.70711L10.7071 12.7071C10.3166 13.0976 9.68341 13.0976 9.29289 12.7071L5.29289 8.70711C4.90237 8.31658 4.90237 7.68342 5.29289 7.29289Z"
                                        fill="#6B7280" />
                                    </svg>
                                  </div>
                                </div>
                                <div class="px-3 text-base font-medium h-8 content-center" v-else>
                                  {{ selectedStopUnitType?.name }}
                                </div>
                              </div>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div v-if="!details.stopDone" class="py-4">
                        <div
                          v-if="isEditMode && (!activeDeviation && !booking.currentStop.secondaryAddressId) && details.stopStarted"
                          class="bg-orange-500 opacity-80 mb-1 p-1 text-center font-medium text-white text-sm">
                          Gem angivelse af gods for at fortsætte
                        </div>
                        <button v-if="!activeDeviation && !booking.currentStop.secondaryAddressId" type="submit"
                          class="flex w-full justify-center rounded-md bg-blue-400 px-3 py-2 font-medium leading-6 text-white shadow-sm hover:bg-gray-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary disabled:opacity-20"
                          :class="{ 'opacity-20 pointer-events-none': (isEditMode && details.stopStarted) || details.canStart === false || (quantity === '' && details.stopStarted)}"
                          @click="handleStopToggleAction()" :disabled="saving">
                          {{ details.stopStarted === false && details.stopDone === false ? 'Påbegynd aflevering' : 'Marker som afleveret' }}
                        </button>
                        <button type="submit"
                          v-if="(activeDeviation || booking.currentStop.secondaryAddressId) && details.canStart"
                          class="flex w-full justify-center rounded-md bg-red-400 px-3 py-2 font-medium leading-6 text-white shadow-sm hover:bg-gray-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary"
                          @click="endDeviation()">
                          Afslut først den aktive afvigelse
                        </button>
                        <label class="text-sm text-center" v-if="!details.canStart && !details.stopDone">
                          Start eller afslut venligst det forrige stop i rækkefølgen, før du fortsætter med dette.
                        </label>
                      </div>
                    </div>
                    <div v-if="selectedOptionStopType === '3'" class="px-2">
                      <div class="py-4">
                        <button type="submit" @click="markAsNoCargo()"
                          class="flex w-full justify-center rounded-md bg-blue-400 px-3 py-2 font-medium leading-6 text-white shadow-sm hover:bg-gray-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary">
                          Marker som intet gods
                        </button>
                      </div>
                    </div>
                    <div v-if="selectedOptionStopType === '4'" class="px-2">
                      <button type="submit" @click="removeStop()"
                        class="flex w-full justify-center rounded-md bg-blue-400 px-3 py-2 font-medium leading-6 text-white shadow-sm hover:bg-gray-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary">
                        Fjern stop
                      </button>
                    </div>
                    <!--
                    <div v-if="details.stopStarted && (selectedOptionStopType === '1' && details.reL_StopType !== 1)" class="px-2">
                      <button type="submit"
                          class="flex w-full justify-center rounded-md bg-blue-400 px-3 py-2 font-medium leading-6 text-white shadow-sm hover:bg-gray-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary disabled:opacity-20"
                          @click="editStopType()" :disabled="saving">
                          Skift stoptype
                        </button>
                    </div>
                    <div v-if="details.stopStarted && (selectedOptionStopType === '2' && details.reL_StopType !== 2)" class="px-2">
                      <button type="submit"
                          class="flex w-full justify-center rounded-md bg-blue-400 px-3 py-2 font-medium leading-6 text-white shadow-sm hover:bg-gray-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary disabled:opacity-20"
                          @click="editStopType()" :disabled="saving">
                          Skift stoptype
                        </button>
                    </div> -->
                  </div>
                </div>

                <div v-if="!hasInvalidAddress" class="relative mt-6 flex-1 bg-white px-4 sm:px-6 py-5">
                  <div class="mb-4">
                    <div class="flex items-center mb-4">
                      <div class="grow">
                        <h1 class="text-xl font-medium text-primary">Bemærkninger til stop</h1>
                      </div>
                    </div>
                    <div class="divide-y p-0 items-stretch">
                      <textarea v-model="localMemoDriver" placeholder="Skriv en bemærkning"
                        class="w-full h-32 p-2 border rounded-md shadow-sm" />
                      <button v-if="isChangedMemoDriver"
                        class="bg-primary text-white px-5 w-full h-10 rounded-md min-w-20 outline-none"
                        @click="saveDriverMemo" :disabled="isLoadingMemoDriver"
                        :class="{ 'opacity-50 pointer-events-none': isLoadingMemoDriver }">
                        <template v-if="isLoadingMemoDriver">
                          <div class="flex items-center justify-center space-x-2">
                            <svg class="animate-spin h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none"
                              viewBox="0 0 24 24">
                              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4">
                              </circle>
                              <path class="opacity-75" fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.231 5.789 3.22 7.778l2.78-2.487z">
                              </path>
                            </svg>
                          </div>
                        </template>
                        <template v-else>
                          <div class="flex items-center justify-center space-x-2">
                            Gem
                          </div>
                        </template>
                      </button>
                    </div>
                  </div>
                  <div class="m-0 flex items-center p-0 border-y border-color:gray" v-if="details.memoExternal">
                    <span class="flex w-full gap-x-4 no-underline py-1.5">
                      <div class="grid grow">
                        <div class="text-base font-medium leading-5 text-gray-800"></div>
                        <div class="text-base font-medium leading-5 truncate text-gray-800">
                          {{ details.memoExternal }}
                        </div>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import bookingRepository from '@/repositories/bookings/bookingRepository'
import stopRepository from '@/repositories/bookings/stopRepository'
import { isNumber } from 'lodash'
import { Ref, computed, onMounted, onUnmounted, ref, watch, watchEffect } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import SmartSelect from '@/components/common/SmartSelect.vue';

const store = useStore()
const route = useRoute()
const id = route.params.id

const props = defineProps({
  isVisible: Boolean
})

const details = computed(() => store.getters['stopStore/getDetails'])
const booking = computed(() => store.getters['bookingStore/booking'])
const stopRoute = computed(() => store.getters['stopStore/stopRoute'])
const activeDeviation = computed(() => store.state.bookingStore.activeDeviation)
const cannotStartTemperatureCheck = computed(() => {
  // Temperature Control should never be undefined, this is until the binary value is fetched
  if (stopRoute.value.temperatureControl === undefined) {
    return true;
  }

  const temperatureIssue = !stopRoute.value.isTemperatureInRange && stopRoute.value.temperatureControl;
  return temperatureIssue && details.value.canStart === true;
  //return false;
});
const originalMemoDriver = ref(details.value.memoDriver || '');
const localMemoDriver = ref(details.value.memoDriver || '');
const isChangedMemoDriver = ref(false);
const isLoadingMemoDriver = ref(false);
const systemLocations = ref([])
const systemLocationsTransformed = ref([])
const stopUnits = ref([])
const selectedOptionStop = ref('')
const selectedOptionStopType = ref('')
const selectedAddressValue = ref<number | null>(null)
const saving = ref(false)
const quantity = ref<any | null>(null)
const selectedStopUnitType = ref()
const hasInvalidAddress = computed(() => details.value.fromAddressStop === 'STOP' || details.value.fromAddressStop === '')
const isEditMode = ref(details.value.stopStarted && !details.value.updatedQuantity && !isNumber(details.value.updatedQuantity));
const activeChangeStop = ref(false)
const mustEnterQuantityWarning = ref(false);
const mustEnterStopUnitWarning = ref(false);
const dropdownRef: Ref<HTMLElement | null> = ref(null);
const isDisabled = ref(false)
const deviationReasons = ref([
  { text: "Test" },
])


const showSelect = ref<boolean>(false);

const close = () => {
  if (details.value.fromAddressStop == '') {
    console.log('please select a valid address')
  } else {
    store.dispatch('stopStore/toggleDetails')
  }
}

const saveOptionStop = async (locationId?: number | null) => {
  if (!locationId || locationId < 0) {
    return;
  }

  try {
    const response = await stopRepository.updateStopAddress(details.value.id, new Date(), locationId)
    if (response.data) {
      store.commit('stopStore/setDetails', response.data)
      store.dispatch('bookingStore/getBooking', id)
      selectedOptionStop.value = ''
      selectedAddressValue.value = null
    }
  } catch (error) {
    console.log('error updating address', error)
  }
}

const handleStopToggleAction = async () => {
  saving.value = true
  if ((details.value.stopStarted === false && details.value.stopDone === false) || activeChangeStop.value === true) {
    await startStop(details.value.id)
    isEditMode.value = true;
  } else {
    await endStop(details.value.id, quantity.value as number)
  }
  saving.value = false
  //activeChangeStop.value = false;
  //isEditMode.value = false;
}

/*
const editStopType = async () => {
  activeChangeStop.value = true;
  handleStopToggleAction();
}*/

const startStop = async (stopId: number) => {
  try {
    const response = await stopRepository.startStop(stopId, new Date(), selectedOptionStopType.value)
    if (response.data) {
      store.commit('stopStore/setDetails', response.data)
      store.dispatch('bookingStore/getBooking', id)
      store.dispatch('logStore/getBookingDriverLog', id)
      isEditMode.value = true;
      isDisabled.value = true;
    }
  } catch (error) {
    console.log('error toggling stop', error)
  }
}

const endStop = async (stopId: number, updatedQuantity: number) => {
  try {
    if (updatedQuantity === 0) {
      console.error('Quantity cannot be 0')
      return;
    }
    const response = await stopRepository.endStop(stopId, new Date(), selectedOptionStopType.value, updatedQuantity, selectedStopUnitType.value.id)
    if (response.data) {
      store.commit('stopStore/setDetails', response.data)
      store.dispatch('bookingStore/getBooking', id)
      store.dispatch('logStore/getBookingDriverLog', id)
      store.commit('stopStore/toggleDetailsOpen')
    }
  } catch (error) {
    console.log('error toggling stop', error)
  }
}

const markAsNoCargo = async () => {
  try {
    const response = await stopRepository.markAsNoCargo(details.value.id, new Date())
    if (response.data) {
      store.commit('stopStore/setDetails', response.data)
      store.dispatch('bookingStore/getBooking', id)
      store.dispatch('logStore/getBookingDriverLog', id)
      store.commit('stopStore/toggleDetailsOpen')
    }
  } catch (error) {
    console.log('error toggling stop', error)
  }
}

const removeStop = async () => {
  try {
    const response = await stopRepository.removeStop(details.value.id)
    if (response && response.data) {
      store.dispatch('bookingStore/getBooking', id)
      store.dispatch('logStore/getBookingDriverLog', id)
      store.commit('stopStore/toggleDetailsOpen')
    }
  } catch (error) {
    console.log('error removing stop', error)
  }
}

const saveDriverMemo = async () => {
  if (isLoadingMemoDriver.value) {
    return;
  }
  try {
    isLoadingMemoDriver.value = true;
    const response = await stopRepository.updateMemoDriver(details.value.id, localMemoDriver.value);
    if (response && response.data) {
      isChangedMemoDriver.value = false;
      originalMemoDriver.value = localMemoDriver.value;
      store.dispatch('bookingStore/getBooking', id)
      store.dispatch('logStore/getBookingDriverLog', id)
    }
  } catch (error) {
    console.log('error saving memo', error)
  }
  isLoadingMemoDriver.value = false;
}

/* Change address */
/*
const editStop = async () => {
  details.value.fromAddressStop = ''
}*/

const toggleEditMode = () => {
  let hasErrors = false;

  if ((!details.value.stopStarted || !details.value.updatedQuantity || !details.value.stopDone)) {
    // Check if the quantity is not entered when trying to toggle edit mode
    const expression = (!quantity.value && !isNumber(quantity.value)) || quantity.value === 0;
    if (isEditMode.value === true && expression) {
      mustEnterQuantityWarning.value = true;
      hasErrors = true;  // Mark that there's an error
    } else {
      mustEnterQuantityWarning.value = false;  // Reset the warning if conditions are met
    }

    // Check if the selected stop unit type is not defined when trying to toggle edit mode
    if (isEditMode.value === true && !selectedStopUnitType.value) {
      mustEnterStopUnitWarning.value = true;
      hasErrors = true;  // Mark that there's another error
    } else {
      mustEnterStopUnitWarning.value = false;  // Reset the warning if conditions are met
    }

    // Only toggle edit mode if there are no errors
    if (!hasErrors) {
      isEditMode.value = !isEditMode.value;
      details.value.updatedQuantity = quantity.value;
      details.value.reL_StopUnit = selectedStopUnitType.value.id;
      store.commit('stopStore/setDetails', details.value)
    }
  }
};

const resetStopUnitWarning = () => {
  mustEnterStopUnitWarning.value = false;
};

const isStartedPickup = computed(() => {
  return details.value.reL_StopType === 1 && details.value.stopStarted
})

const isStartedDelivery = computed(() => {
  return details.value.reL_StopType === 2 && details.value.stopStarted
})

const getSystemLocations = async () => {
  const response = await bookingRepository.getSystemLocations()
  if (response.data) {
    systemLocations.value = response.data
    systemLocationsTransformed.value = transformOptions(systemLocations.value)
  }
}

const getStopUnits = async () => {
  const response = await stopRepository.getStopUnits()
  if (response.data) {
    stopUnits.value = response.data
  }
}

const transformOptions = (options: any) => {
  return options.map((option: any) => ({ id: option.id, text: `${option.name}` }));
};

onMounted(async () => {
  getSystemLocations()
  getStopUnits()
  document.addEventListener('click', handleClickOutside);
})

onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside);
});

const handleClickOutside = (event: MouseEvent) => {
  if (dropdownRef.value && !dropdownRef.value.contains(event.target as Node)) {
    showSelect.value = false;
  }
};

if (details.value.toAddressName === "STOP") {
  deviationReasons.value.unshift({ text: "Ukendt destination" })
}

const endDeviation = async () => {
  if (booking.value.currentStop.secondaryAddressId) {
    // Handle imposed deviation
    const response = await stopRepository.toggleSecondaryRoute(booking.value.currentStop.id, undefined);
    if (response && response.data) {
      store.commit('stopStore/setDetails', response.data)
      store.dispatch('bookingStore/getBooking', booking.value.id)
    }
    return;
  }
  const payload = {
    deviationId: activeDeviation.value.id,
    endTime: new Date(),
  };
  await store.dispatch('bookingStore/endDeviation', payload);
}

const handleAddressValueUpdate = (value: any) => {
  selectedAddressValue.value = value;
  console.log('Selected Value:', selectedAddressValue.value);
};

watchEffect(() => {
  if (props.isVisible) {
    // Code to execute when the prop isVisible changes to true
  } else {
    // Code to execute when the prop isVisible changes to false
  }
})

watch(quantity, (newValue) => {
  // Update warning based on quantity content when in edit mode
  if (isEditMode.value) {
    if (newValue === 0) {
      mustEnterQuantityWarning.value = true;
    } else {
      mustEnterQuantityWarning.value = !newValue && !isNumber(newValue);
    }
  }
});

watchEffect(() => {
  if (details.value.reL_StopType) {
    selectedOptionStopType.value = details.value.reL_StopType.toString()
  }
  console.log('watchEffect: ', details.value)
  if (details.value.updatedQuantity) {
    quantity.value = details.value.updatedQuantity;
  }

  // If no predefined unit type is set, enable edit mode
  if (!details.value.reL_StopUnit) {
    isEditMode.value = true;
  }
  else {
    selectedStopUnitType.value = stopUnits.value.find((unit: any) => unit.id === details.value.reL_StopUnit)
  }
})

watch(details, (newDetails, oldDetails) => {
  console.log('watch: ', details.value)

  if (details.value.updatedQuantity || details.value.updatedQuantity > 0) {
    quantity.value = details.value.updatedQuantity;
  } else {
    quantity.value = null;
  }
  localMemoDriver.value = newDetails.memoDriver || '';
  originalMemoDriver.value = newDetails.memoDriver || '';
}, { immediate: true });

watch(localMemoDriver, (newVal, oldVal) => {
  isChangedMemoDriver.value = newVal !== originalMemoDriver.value;
});

function ngOnInit() {
  throw new Error('Function not implemented.')
}
</script>

<style scoped>
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
</style>
