/* eslint-disable no-console */

import { register } from 'register-service-worker'

if (true || process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready (reg: ServiceWorkerRegistration) {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
      console.log(reg)
    },
    registered (reg: ServiceWorkerRegistration) {
      console.log('Service worker has been registered.')
      console.log(reg)
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound (reg: ServiceWorkerRegistration) {
      console.log('New content is downloading.')

      if(!reg || !reg.installing) {
        return
      }

      reg.installing.postMessage({ type: 'install' })
    },
    updated (reg: ServiceWorkerRegistration) {
      console.log('New content is available; please refresh.')

      if(!reg || !reg.waiting) {
        return
      }

      reg.waiting.postMessage({ type: 'skipWaiting' })
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
