import { UserManager, WebStorageStateStore, User } from 'oidc-client-ts'
import { apiConfig } from './apiConfig'
class ApplicationUserManager extends UserManager {
  constructor () {
    // authority: 'https://localhost:7169',
    // client_id: 'WebClient',
    // redirect_uri: 'http://localhost:8080/callback',
    // response_type: 'code',
    // scope: 'api.read api.write',
    // post_logout_redirect_uri: 'http://localhost:8080',
    // silent_redirect_uri: 'http://localhost:8080/silent.html'

    super({
      authority: `${apiConfig.urlIdentity}`,
      client_id: 'WebClient',
      client_secret: `${apiConfig.clientSecret}`,
      response_type: 'code',
      scope: 'api.read api.write openid offline_access',
      userStore: new WebStorageStateStore({ store: window.localStorage }),
      loadUserInfo: true,
      automaticSilentRenew: true,
      validateSubOnSilentRenew: false,
      redirect_uri: `${window.location.origin}/callback`,
      silent_redirect_uri: `${window.location.origin}/silent-refresh`,
      post_logout_redirect_uri: `${window.location.origin}`
    })
  }

  async login (userName: string, password: string): Promise<User> {
    return await this.signinResourceOwnerCredentials({ username: userName, password: password, skipUserInfo: true })
  }

  async logout (): Promise<void> {
    window.localStorage.removeItem('WebClient-Token')
    return await this.removeUser()
  }

  async getUserInfo (): Promise<User | null> {
    try {
      const user = await this.getUser()
      if (user) {
        window.localStorage.setItem('WebClient-Token', user.access_token)
        await this.signinSilent()
      }
      return user
    } catch (error) {
      console.error(error)
      return Promise.reject(error)
    }
  }

  public renewToken (): Promise<void> {
    try {
      return this.signinSilentCallback()
    } catch (error) {
      console.error(error)
      return Promise.reject(error)
    }
  }

  getAccessToken (): string | null {
    return window.localStorage.getItem('WebClient-Token')
  }

  async isAuthenticated () {
    try {
      const user = await this.getUserInfo()
      if (!user) {
        return false
      }
    } catch (error: any) {
      // Håndter fejlen her, f.eks. ved at omdirigere til login siden igen
      if ((error as any).toString().indexOf('invalid_grant') >= 0) {
        await this.logout()
        return false
      } else {
        // Håndter andre fejl
        console.error(error)
      }
    }
    return true
  }
}

const applicationUserManager = new ApplicationUserManager()
export { applicationUserManager as default }
